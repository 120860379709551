import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { TRANSACTION_LIST } from '@/store/mutation-types'

storage.addPlugin(expirePlugin)
const transaksi = {
  state: {
    transactionList: []
  },

  mutations: {
    ADD_TRANSACTION_LIST: (state, data) => {
      const exists = state.transactionList.filter(x => x.nomor == data.nomor)
      if (exists.length > 0) {
        state.transactionList[data.nomor] = data
      } else {
        state.transactionList.push(data)
      }
    },
    LOAD_TRANSACTION_LIST: (state, data) => {
      state.transactionList = data
    },
    REMOVE_TRANSACTION_LIST: (state, data) => {
      const find = state.transactionList.filter(x => x.nomor != data.nomor)
      state.transactionList = find
    }
  },
  actions: {
    addTransactionList ({ commit }, data) {
      // if (data.data.length > 0) {
      storage.set(TRANSACTION_LIST + '.' + data.nomor, data, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
      commit('ADD_TRANSACTION_LIST', data)
      // }
      console.log('simpan log ' + data.nomor)
      // else {
      //   storage.remove(TRANSACTION_LIST + '.' + data.nomor)
      //   commit('REMOVE_TRANSACTION_LIST', data)
      // }
    },
    clearTransaction ({ commit }, data) {
      storage.remove(TRANSACTION_LIST + '.' + data.nomor)
      commit('REMOVE_TRANSACTION_LIST', data)
    },
    loadTransactionList ({ commit }, data) {
      commit('LOAD_TRANSACTION_LIST', data)
    }
  }
}

export default transaksi
