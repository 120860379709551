export default {
  'menu.welcome': 'Welcome',
  'menu.home': 'Home',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.transaction.form': 'Tambah Transaksi',
  'menu.transaction.list': 'Transaksi',
  'menu.transaction.refund': 'Pembatalan',
  'menu.transaction.retur': 'Retur Supplier',
  'menu.transaction': 'Transaksi',
  'menu.customer.form': 'Tambah Pelanggan',
  'menu.customer.list': 'Pelanggan',
  'menu.cashflow.form': 'Tambah Kas',
  'menu.cashflow.list': 'Kas',
  'menu.customer': 'Pelanggan',
  'menu.cashflow': 'Kas',
  'menu.report': 'Laporan',
  'menu.staff': 'Pegawai',
  'menu.staff.list': 'Pegawai',
  'menu.staff.form': 'Tambah Pegawai',
  'menu.report.sales': 'Penjualan',
  'menu.report.paymentMethods': 'Metode Pembayaran',
  'menu.report.topsales': 'Produk',
  'menu.report.customers': 'Pelanggan',
  'menu.report.discounts': 'Diskon',
  'menu.form': 'Form',
  'menu.product': 'Produk',
  'menu.setting': 'Setting',
  'menu.product.catalog': 'Produk Saya',
  'menu.product.product-form': 'Form Produk',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'My Profile',
  'menu.account.settings': 'General',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout'
}
