import Vue from 'vue'
import moment from 'moment'
// import 'moment/locale/id'
// moment.locale('id')

Vue.filter('RupiahFormat', function (value) {
  if (!value) {
    return '0'
  }
  return 'Rp ' + value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.') + ',-'
})

Vue.filter('fromNow', function (value) {
  const pattern = 'DD/MM/YYYY HH:mm:ss'
  moment.locale('id')
  return moment(value).format(pattern)
  // return moment(value).fromNow()
})

Vue.filter('RupiahFormatStrong', function (value) {
  if (!value) {
    return '0'
  }
  return '<strong>Rp ' + value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.') + ',-</strong>'
})

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('printFormat', function (dataStr, pattern = 'Do MMMM YYYY, h:mm:ss a') {
  moment.locale('id')
  return moment(dataStr).format(pattern)
})

Vue.filter('hariFormat', function (dataStr, pattern = 'dddd, Do MMMM YYYY') {
  moment.locale('id')
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
