<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
  >
    <!-- Ads begin
      广告代码 真实项目中请移除
      production remove this Ads
    -->
    <!-- Ads end -->

    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template #menuHeaderRender>
      <div>
        <img src="@/assets/logo.svg" />
        <h1>{{ title }}</h1>
      </div>
    </template>
    <template #headerContentRender>
      <div>
        <a-tooltip
          title="Tambah Transaksi"
          placement="bottom">
          <a-icon
            type="plus"
            style="font-size: 18px; cursor: pointer"
            @click="tambahBaru" />
        </a-tooltip>
        <a-tooltip
          style="margin-left: 20px"
          title="Tambah Produk"
          placement="bottom"
          v-if="['admin', 'operator'].includes(currentUser?.roles?.name)"
        >
          <a-icon
            type="plus-square"
            style="font-size: 18px; cursor: pointer"
            @click="tambahProduk" />
        </a-tooltip>
        <a-popover
          placement="bottomLeft"
          style="margin-left: 20px"
          v-if="['admin', 'operator'].includes(currentUser?.roles?.name)"
          title="Lanjutkan Transaksi"
          trigger="hover"
        >
          <template slot="content">
            <a-list :data-source="trans">
              <a-list-item
                slot="renderItem"
                slot-scope="item, index">
                <!-- <div slot="actions">
                  <a-popconfirm title="Yakin hapus data ini？" @confirm="bringBackStok(item)">
                    <a-icon type="close"> </a-icon>
                  </a-popconfirm>
                </div> -->
                <a
                  v-if="item.customer != ''"
                  href="javascript:void()"
                  @click="navigate(item)">
                  {{ index + 1 }} . INV - {{ item.nomor }} - {{ item.customer }}
                </a>
                <a
                  v-else
                  href="javascript:void()"
                  @click="navigate(item)">
                  {{ index + 1 }} . INV - {{ item.nomor }}
                </a>
              </a-list-item>
            </a-list>
          </template>
          <a-badge
            v-if="trans.length > 0"
            :count="trans.length">
            <a-icon
              type="shopping-cart"
              style="font-size: 18px; cursor: pointer"
              @click="
                () => {
                  $router.push('/transaction/add')
                }
              "
            />
          </a-badge>
        </a-popover>
      </div>
    </template>

    <setting-drawer
      v-if="isDev"
      :settings="settings"
      @change="handleSettingChange">
      <div style="margin: 12px 0">This is SettingDrawer custom footer content.</div>
    </setting-drawer>
    <template #rightContentRender>
      <right-content
        :top-menu="settings.layout === 'topmenu'"
        :is-mobile="isMobile"
        :theme="settings.theme" />
    </template>
    <!-- custom footer / 自定义Footer -->
    <template #footerRender>
      <global-footer />
    </template>
    <router-view />
  </pro-layout>
</template>

<script>
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState, mapActions } from 'vuex'
import storage from 'store'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'BasicLayout',
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter
  },
  data () {
    return {
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      isDev: process.env.NODE_ENV === 'development' || process.env.VUE_APP_PREVIEW === 'true',
      menus: [],
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        theme: defaultSettings.navTheme,
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,
        hideHintAlert: false,
        hideCopyButton: false
      },
      query: {},
      isMobile: false
    }
  },
  computed: {
    ...mapState({
      mainMenu: (state) => state.permission.addRouters,
      currentUser: (state) => state.user,
      trans: (state) => state.transaksi.transactionList
    })
  },
  created () {
    const routes = this.mainMenu.find((item) => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
    this.loadTransaksi()
  },
  methods: {
    bringBackStok (item) {
      const target = 'transaction_list.' + item.nomor
      const currentTransaksi = storage.get(target)
      const url = `update_mass_stok`
      const stocks = []
      if (currentTransaksi) {
        if (currentTransaksi.data) {
          currentTransaksi.data.forEach((x) => {
            stocks.push({
              id_varian_fk: x.id_varian_fk,
              jumlah: x.jumlah
            })
          })
          this.$http
            .post(url, {
              data: stocks
            })
            .then((x) => {
              if (x.result) {
                console.log(`success update stok jadi ${x.result.jumlah}`)
                storage.remove(target)
                this.loadTransaksi()
              }
            })
            .catch((ex) => {
              this.$message.error(ex.message)
            })
        } else {
          storage.remove(target)
          this.loadTransaksi()
        }
      }
    },
    tambahBaru () {
      let routes = '/transaction/add'
      if (this.currentUser.roles.name == 'teknisi') routes = '/technician/add'
      if (this.currentUser.roles.name == 'marketplace') routes = '/marketplace/add'
      const routeData = this.$router.resolve(routes)
      if (this.currentUser.roles.name == 'teknisi' || this.currentUser.roles.name == 'marketplace') { this.$router.push(routes) } else window.open(routeData.href, '_blank')
    },
    tambahProduk () {
      this.$router.push('/product/tambah')
    },
    navigate (item) {
      const url = '/transaction/add/' + item.nomor
      const routeData = this.$router.resolve(url)
      window.open(routeData.href, '_blank')
    },
    ...mapActions(['loadTransactionList']),
    loadTransaksi () {
      const all = []
      storage.each(function (value, key) {
        if (key.startsWith('transaction_list.')) {
          all.push(value)
        }
      })
      this.loadTransactionList(all)
    },
    i18nRender,
    handleMediaQuery (val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse (val) {
      this.collapsed = val
    },
    handleSettingChange ({ type, value }) {
      console.log('type', type, value)
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    }
  }
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
