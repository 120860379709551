/* eslint-disable */
export const printANSI = () => {
  // console.clear()
  console.log('[Feraz Jaya] created()')
  // ASCII - ANSI Shadow
  let text = `
  ____|                     (_)         | |                                   | |          | |    
| |__   _ __   __ _  __ _ ___ _  ___ _ __| |     __ ___      ____ _ _ __   __ _| |_ ___  ___| |__  
| | ____ | | | | (_ | | (_ | \__ \ | __ / |  | | ___ | (_ | |\ V  V / (_ | | | | | (_ | | || __ / (__ | | | |
                 | | __ / |                   
                 | _ |                                                      | ___ /
\t\t\t\t\tPublished ${APP_VERSION} -${GIT_HASH} @lawangtech.com
\t\t\t\t\tBuild date: ${BUILD_DATE} `
  console.log(`% c${text} `, 'color: #fc4d50')
}
