export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return 'Selamat' + hour < 9 ? 'Pagi' : hour <= 11 ? 'Pagi' : hour <= 13 ? 'Siang' : hour < 20 ? 'Siang' : 'Malam'
}

export function handleError (ex) {
  let message = ex.message
  if (ex.response) {
    if (ex.response.data) {
      if (ex.response.data?.errors) {
        const errors = Object.keys(ex.response.data.errors)
        const allError = []
        errors.forEach(element => {
          allError.push(ex.response.data.errors[element][0])
        })
        message = allError.join(',')
      }
    }
  } else {
    message = ex.message
  }
  return message
}

export function welcome () {
  const arr = ['Kamu mau makan apa?', 'Selamat datang ', 'Kurasa kamu mungkin lelah']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function makeId (length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

export function downloadFile (axios, url, data, name) {
  axios({
    method: 'POST',
    url: url,
    responseType: 'blob',
    data: data
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name + '.xlsx')
    document.body.appendChild(link)
    link.click()
  })
};

export function convertTextToHtml (text) {
  var html = ''
  var lines = text.split('\n')

  for (var i = 0; i < lines.length; i++) {
    html += '<p>' + lines[i] + '</p>'
  }
  return html
}
