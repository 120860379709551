// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/dashboard/analysis',
    children: [
      // dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/analysis',
        component: RouteView,
        meta: { title: 'menu.dashboard', keepAlive: true, icon: 'home', permission: ['dashboard'] },
        children: [
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: 'Home',
            component: () => import('@/views/dashboard/Analysis'),
            meta: { title: 'menu.home', keepAlive: false, permission: ['dashboard'] },
          },
          {
            path: '/dashboard/statistik',
            name: 'Statistik',
            component: () => import('@/views/dashboard/Home'),
            meta: { title: 'Statistik', keepAlive: false, permission: ['dashboard-admin'] },
          },
          // 外部链接
          // {
          //   path: 'https://www.baidu.com/',
          //   name: 'Monitor',
          //   meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          // },
        ],
      },
      {
        path: '/transaction',
        redirect: '/transaction/list',
        component: RouteView,
        meta: { title: 'menu.transaction', icon: 'money-collect', permission: ['transaksi'] },
        children: [
          {
            path: '/transaction/add',
            name: 'TransactionForm',
            component: () => import('@/views/transaction/TransactionForm'),
            meta: { title: 'Transaksi', keepAlive: true, permission: ['transaksi'] },
          },
          {
            path: '/transaction/invoice/:id([1-9]\\d*)?',
            name: 'TransactionInvoice',
            component: () => import('@/views/transaction/TransactionForm'),
            meta: { title: 'Lanjut Transaksi', keepAlive: true, permission: ['transaksi'] },
            hidden: true,
          },
          {
            path: '/transaction/list',
            name: 'TransactionList',
            component: () => import('@/views/transaction/TransactionListN'),
            meta: { title: 'Riwayat Transaksi', keepAlive: true, permission: ['transaksi'] },
          },
          {
            hidden: true,
            path: '/transaction/view/:id([1-9]\\d*)?',
            component: () => import('@/views/transaction/Print'),
            meta: { title: 'Lihat Invoice', keepAlive: true, permission: ['transaksi'] },
          },
          {
            hidden: true,
            path: '/transaction/refund',
            name: 'TransactionRefund',
            component: () => import('@/views/transaction/TransactionRefund'),
            meta: { title: 'menu.transaction.refund', keepAlive: true, permission: ['transaksi'] },
          },
          {
            path: '/transaction/retur',
            name: 'TransactionRetur',
            component: () => import('@/views/transaction/TransactionReturList'),
            meta: { title: 'menu.transaction.retur', keepAlive: true, permission: ['transaksi'] },
          },
          {
            path: '/transaction/add/:id',
            hidden: true,
            name: 'TransactionForm',
            component: () => import('@/views/transaction/TransactionForm'),
            meta: { title: 'menu.transaction.form', keepAlive: true, permission: ['transaksi'] },
          },

          {
            path: '/transaction/retur_supplier',
            hidden: true,
            name: 'ReturSupplier',
            component: () => import('@/views/transaction/ReturSupplierList'),
            meta: { title: 'menu.transaction.form', keepAlive: true, permission: ['transaksi'] },
          },
        ],
      },
      {
        path: '/product',
        redirect: '/product/list',
        component: RouteView,
        meta: { title: 'menu.product', icon: 'form', permission: ['produk'] },
        children: [
          {
            path: '/product/list',
            name: 'ProductList',
            component: () => import('@/views/product/ProductList'),
            meta: { title: 'menu.product.catalog', keepAlive: true, permission: ['produk'] },
          },
          {
            path: '/product/tambah',
            hidden: true,
            name: 'Form Product',
            component: () => import('@/views/product/ProductForm'),
            meta: { title: 'menu.product.product-form', keepAlive: true, permission: ['produk'] },
          },
          {
            path: '/product/edit/:id([1-9]\\d*)?',
            hidden: true,
            name: 'Edit Product',
            component: () => import('@/views/product/ProductForm'),
            meta: { title: 'menu.product.product-form', keepAlive: true, permission: ['produk'] },
          },
          {
            path: '/wishlist/list',
            hidden: true,
            name: 'WishList',
            component: () => import('@/views/wishlist/WishList'),
            meta: { title: 'Barang Belum Ada', keepAlive: true, permission: ['produk'] },
          },
        ],
      },
      {
        path: '/report',
        redirect: '/report/sales',
        component: RouteView,
        meta: { title: 'Laporan Penjualan', icon: 'database', permission: ['report', 'report-methodpayment'] },
        children: [
          {
            path: '/report/history',
            name: 'HIstory',
            component: () => import('@/views/report/HistoryReportDetail'),
            meta: { title: 'Riwayat Transaksi', keepAlive: true, permission: ['report'] },
          },
          {
            path: '/report/sales',
            name: 'ReportSales',
            component: () => import('@/views/report/SalesReport'),
            meta: { title: 'menu.report.sales', keepAlive: true, permission: ['report'] },
          },
          {
            path: '/report/stock',
            name: 'ReportStock',
            component: () => import('@/views/report/StockReport'),
            meta: { title: 'Stok Produk', keepAlive: true, permission: ['report'] },
          },
          {
            path: '/report/paymentmethod',
            name: 'PaymentMethods',
            component: () => import('@/views/report/PaymentMethodsReport'),
            meta: { title: 'menu.report.paymentMethods', keepAlive: true, permission: ['report-methodpayment'] },
          },
          {
            path: '/report/products',
            name: 'Products',
            component: () => import('@/views/report/ProductReport'),
            meta: { title: 'Produk', keepAlive: true, permission: ['report'] },
          },
          {
            path: '/report/topsales',
            name: 'TopSales',
            component: () => import('@/views/report/TopSalesReport'),
            meta: { title: 'Produk Terlaris', keepAlive: true, permission: ['report'] },
          },
          {
            path: '/report/customer',
            name: 'Customers',
            component: () => import('@/views/report/CustomersReport'),
            meta: { title: 'menu.report.customers', keepAlive: true, permission: ['report'] },
          },
          {
            hidden: true,
            path: '/report/customer/view/:id([1-9]\\d*)?',
            hidden: true,
            name: 'View Customer Transaction',
            component: () => import('@/views/report/CustomersReportDetail'),
            meta: { title: 'Laporan Transaksi Pelanggan', keepAlive: true, permission: ['produk'] },
          },
          {
            path: '/report/discount',
            name: 'Discounts',
            component: () => import('@/views/report/DiscountsReport'),
            meta: { title: 'menu.report.discounts', keepAlive: true, permission: ['produk'] },
          },
        ],
      },
      {
        path: '/marketplace',
        redirect: '/marketplace/list',
        component: RouteView,
        meta: { title: 'Marketplace', icon: 'shop', permission: ['marketplace'] },
        children: [
          {
            path: '/marketplace/add',
            name: 'Transaksi',
            component: () => import('@/views/marketplace/forms/MarketplaceForm'),
            meta: { title: 'Transaksi', keepAlive: true, permission: ['marketplace'] },
          },
          {
            path: '/marketplace/edit/:id',
            name: 'Transaksi',
            component: () => import('@/views/marketplace/forms/MarketplaceForm'),
            meta: { title: 'Transaksi', keepAlive: true, permission: ['marketplace'] },
            hidden: true,
          },
          {
            path: '/marketplace/kasbon/tambah',
            hidden: true,
            name: 'Form Product',
            component: () => import('@/views/marketplace/forms/MarketplaceForm'),
            meta: { title: 'menu.product.product-form', keepAlive: true, permission: ['marketplace'] },
          },
          {
            path: '/marketplace/view/:id([1-9]\\d*)?',
            name: 'MarketplaceView',
            component: () => import('@/views/marketplace/MarketplaceView'),
            meta: { title: 'Lihat Pekerjaan marketplace', keepAlive: true, permission: ['marketplace'] },
            hidden: true,
          },
          {
            path: '/marketplace/list',
            name: 'MarketplaceList',
            component: () => import('@/views/marketplace/MarketplaceList'),
            meta: { title: 'Riwayat Transaksi', keepAlive: true, permission: ['marketplace'] },
          },
          {
            hidden: true,
            path: '/marketplace/view/:id([1-9]\\d*)?',
            component: () => import('@/views/marketplace/Print'),
            meta: { title: 'Print Marketplace', keepAlive: true, permission: ['marketplace'] },
          },
          // {
          //   path: '/technician/retur',
          //   name: 'technician',
          //   component: () => import('@/views/technician/technician'),
          //   meta: { title: 'menu.technician.retur', keepAlive: true, permission: ['form'] }
          // },
          // {
          //   path: '/marketplace/add/:id',
          //   hidden: true,
          //   name: 'technician',
          //   component: () => import('@/views/technician/TechnicianForm'),
          //   meta: { title: 'menu.technician.form', keepAlive: true, permission: ['teknisi'] },
          // },
        ],
      },
      {
        path: '/report_marketplace',
        redirect: '/report_marketplace/sales',
        component: RouteView,
        meta: { title: 'Laporan Marketplace', icon: 'database', permission: ['marketplace'] },
        children: [
          {
            path: '/report_marketplace/history',
            name: 'ReportMarketplaceHistory',
            component: () => import('@/views/report_marketplace/HistoryReportDetail'),
            meta: { title: 'Riwayat Transaksi', keepAlive: true, permission: ['marketplace'] },
          },
          {
            path: '/report_marketplace/sales',
            name: 'ReportMarketplaceSales',
            component: () => import('@/views/report_marketplace/SalesReport'),
            meta: { title: 'menu.report.sales', keepAlive: true, permission: ['marketplace-report'] },
          },
          {
            path: '/report_marketplace/topsales',
            name: 'TopSalesMarketplace',
            component: () => import('@/views/report_marketplace/TopSalesReport'),
            meta: { title: 'Produk Terlaris', keepAlive: true, permission: ['marketplace-report'] },
          },
          {
            path: '/report_marketplace/retur',
            name: 'ReturMarketplace',
            component: () => import('@/views/report_marketplace/ReturReport'),
            meta: { title: 'Retur', keepAlive: true, permission: ['marketplace'] },
          },
        ],
      },
      {
        path: '/technician',
        redirect: '/technician/list',
        component: RouteView,
        meta: { title: 'Teknisi', icon: 'user', permission: ['teknisi-transaksi'] },
        children: [
          {
            path: '/technician/add',
            name: 'TransaksiTeknisi',
            component: () => import('@/views/technician/TechnicianJobForm'),
            meta: { title: 'Transaksi', keepAlive: true, permission: ['teknisi-transaksi'] },
          },
          {
            path: '/technician/edit/:id',
            name: 'Transaksi',
            component: () => import('@/views/technician/TechnicianJobForm'),
            meta: { title: 'Transaksi', keepAlive: true, permission: ['teknisi-transaksi'] },
            hidden: true,
          },
          {
            path: '/technician/kasbon/tambah',
            hidden: true,
            name: 'Form Product',
            component: () => import('@/views/technician/forms/KasbonPribadiForm'),
            meta: { title: 'menu.product.product-form', keepAlive: true, permission: ['teknisi'] },
          },

          {
            path: '/technician/work/:id([1-9]\\d*)?',
            name: 'TechnicianWork',
            component: () => import('@/views/technician/TechnicianWork'),
            meta: { title: 'Lihat Pekerjaan Teknisi', keepAlive: true, permission: ['teknisi'] },
            hidden: true,
          },
          {
            path: '/technician/view/:id([1-9]\\d*)?',
            name: 'TechnicianView',
            component: () => import('@/views/technician/TechnicianView'),
            meta: { title: 'Lihat Pekerjaan Teknisi', keepAlive: true, permission: ['teknisi'] },
            hidden: true,
          },
          {
            path: '/technician/view/admin/:id([1-9]\\d*)?',
            name: 'TechnicianViewAdmin',
            component: () => import('@/views/technician/TechnicianViewAdmin'),
            meta: { title: 'Lihat Pekerjaan Teknisi', keepAlive: true, permission: ['teknisi-transaksi'] },
            hidden: true,
          },
          {
            path: '/technician/list',
            name: 'TechnicianList',
            component: () => import('@/views/technician/TechnicianList'),
            meta: { title: 'Riwayat Transaksi', keepAlive: true, permission: ['teknisi-transaksi'] },
          },
          {
            path: '/technician/kasbon',
            name: 'TechnicianKasbon',
            component: () => import('@/views/technician/TechnicianKasbon'),
            meta: { title: 'Kasbon', keepAlive: true, permission: ['teknisi'] },
          },
          {
            path: '/technician/kasbon_pribadi',
            name: 'TechnicianKasbonPribadi',
            component: () => import('@/views/technician/TechnicianKasbonPribadi'),
            meta: { title: 'Kasbon Pribadi', keepAlive: true, permission: ['teknisi'] },
          },
          {
            path: '/technician/transaksi_manual',
            name: 'TechnicianManualTransaction',
            component: () => import('@/views/technician/TechnicianManualTransaction'),
            meta: { title: 'Transaksi Manual', keepAlive: true, permission: ['teknisi'] },
          },
          {
            path: '/technician/retur',
            name: 'TechnicianRetur',
            component: () => import('@/views/technician/TechnicianRetur'),
            meta: { title: 'Retur', keepAlive: true, permission: ['teknisi'] },
          },
          {
            hidden: true,
            path: '/technician/view/:id([1-9]\\d*)?',
            component: () => import('@/views/technician/Print'),
            meta: { title: 'menu.technician.print', keepAlive: true, permission: ['teknisi'] },
          },
          // {
          //   path: '/technician/retur',
          //   name: 'technician',
          //   component: () => import('@/views/technician/technician'),
          //   meta: { title: 'menu.technician.retur', keepAlive: true, permission: ['form'] }
          // },
          {
            path: '/technician/add/:id',
            hidden: true,
            name: 'technician',
            component: () => import('@/views/technician/TechnicianForm'),
            meta: { title: 'menu.technician.form', keepAlive: true, permission: ['teknisi'] },
          },

          // {
          //   path: '/technician/retur_supplier',
          //   hidden: true,
          //   name: 'ReturSupplier',
          //   component: () => import('@/views/technician/ReturSupplierList'),
          //   meta: { title: 'menu.technician.form', keepAlive: true, permission: ['form'] }
          // }
        ],
      },
      {
        path: '/report_teknisi',
        redirect: '/report_teknisi/sales',
        component: RouteView,
        meta: { title: 'Laporan Teknisi', icon: 'database', permission: ['teknisi'] },
        children: [
          {
            path: '/report_teknisi/history',
            name: 'History',
            component: () => import('@/views/report_teknisi/HistoryReportDetail'),
            meta: { title: 'Riwayat Transaksi', keepAlive: true, permission: ['teknisi'] },
          },
          {
            path: '/report_teknisi/dana',
            name: 'Dana Tersedia',
            component: () => import('@/views/report_teknisi/DanaReport'),
            meta: { title: 'Dana Tersedia', keepAlive: true, permission: ['teknisi'] },
          },
          {
            path: '/report_teknisi/riwayat_dana',
            name: 'Riwayat Penarikan Dana',
            component: () => import('@/views/report_teknisi/DanaReportHistory'),
            meta: { title: 'Riwayat Penarikan Dana', keepAlive: true, permission: ['teknisi'] },
          },
          {
            path: '/report_teknisi/sales',
            name: 'Laporan Sales',
            component: () => import('@/views/report_teknisi/SalesReport'),
            meta: { title: 'Laporan Sales', keepAlive: true, permission: ['teknisi-report'] },
          },
          // {
          //   path: '/report_teknisi/products',
          //   name: 'Products',
          //   component: () => import('@/views/report_teknisi/ProductReport'),
          //   meta: { title: 'Produk', keepAlive: true, permission: ['teknisi'] }
          // },
          {
            path: '/report_teknisi/topsales',
            name: 'Sparepart Terpakai',
            component: () => import('@/views/report_teknisi/TopSalesReport'),
            meta: { title: 'Sparepart Terpakai', keepAlive: true, permission: ['teknisi-report'] },
          },
          // {
          //   path: '/report_teknisi/customer',
          //   name: 'Customers',
          //   component: () => import('@/views/report/CustomersReport'),
          //   meta: { title: 'menu.report.customers', keepAlive: true, permission: ['dashboard'] }
          // },
          {
            hidden: true,
            path: '/report_teknisi/customer/view/:id([1-9]\\d*)?',
            hidden: true,
            name: 'View Customer Transaction',
            component: () => import('@/views/report_teknisi/CustomersReportDetail'),
            meta: { title: 'Laporan Transaksi Pelanggan', keepAlive: true, permission: ['teknisi'] },
          },
        ],
      },

      {
        path: '/staff',
        redirect: '/staff/list',
        component: RouteView,
        meta: { title: 'menu.staff', icon: 'team', permission: ['staff'] },
        children: [
          {
            path: '/staff/list',
            name: 'StaffList',
            component: () => import('@/views/staff/StaffList'),
            meta: { title: 'menu.staff.list', keepAlive: true, permission: ['staff'] },
          },
          {
            path: '/staff/add',
            name: 'StaffForm',
            hidden: true,
            component: () => import('@/views/staff/StaffForm'),
            meta: { title: 'menu.staff.form', keepAlive: true, permission: ['staff'] },
          },
          {
            path: '/staff/edit/:id([1-9]\\d*)?',
            hidden: true,
            name: 'Edit Staff',
            component: () => import('@/views/staff/StaffForm'),
            meta: { title: 'Edit Staff', keepAlive: true, permission: ['staff'] },
          },
        ],
      },
      {
        path: '/cashflow',
        redirect: '/cashflow/list',
        component: RouteView,
        meta: { title: 'Keuangan', icon: 'money-collect', permission: ['keuangan'] },
        children: [
          {
            path: '/cashflow/list',
            name: 'CashflowList',
            component: () => import('@/views/cashflow/CashflowList'),
            meta: { title: 'menu.cashflow.list', keepAlive: true, permission: ['keuangan-kas'] },
          },
          {
            path: '/saldo/list',
            name: 'SaldoList',
            component: () => import('@/views/saldo/SaldoList'),
            meta: { title: 'Saldo', keepAlive: true, permission: ['keuangan-saldo'] },
          },
          {
            path: '/saldo/add/:id([1-9]\\d*)?',
            name: 'SaldoForm',
            hidden: true,
            component: () => import('@/views/saldo/SaldoForm'),
            meta: { title: 'Saldo', keepAlive: true, permission: ['keuangan'] },
          },
          {
            path: '/saldo/view/:id([1-9]\\d*)?',
            hidden: true,
            name: 'View Saldo',
            component: () => import('@/views/saldo/SaldoForm'),
            meta: { title: 'View Saldo', keepAlive: true, permission: ['keuangan'] },
          },
          {
            path: '/cashflow/add/:id([1-9]\\d*)?',
            name: 'CashFlowForm',
            hidden: true,
            component: () => import('@/views/cashflow/CashflowForm'),
            meta: { title: 'menu.cashflow.form', keepAlive: true, permission: ['keuangan'] },
          },
          {
            path: '/cashflow/edit/:id([1-9]\\d*)?',
            hidden: true,
            name: 'Edit Kas',
            component: () => import('@/views/cashflow/CashflowForm'),
            meta: { title: 'Edit Kas', keepAlive: true, permission: ['keuangan'] },
          },
          {
            hidden: true,
            path: '/cashflow/view/:id([1-9]\\d*)?',
            hidden: true,
            name: 'View Kategori Kas',
            component: () => import('@/views/cashflow/CashflowDetailList'),
            meta: { title: 'View Kategori Kas', keepAlive: true, permission: ['keuangan'] },
          },
        ],
      },
      {
        path: '/customer',
        redirect: '/customer/list',
        component: BlankLayout,
        meta: { title: 'menu.customer', icon: 'solution', permission: ['konsumen'] },
        children: [
          {
            path: '/customer/list',
            name: 'CustomerList',
            component: () => import('@/views/customer/CustomerList'),
            meta: { title: 'menu.customer.list', keepAlive: true, permission: ['konsumen'] },
          },
          {
            path: '/customer/add',
            name: 'CustomerForm',
            hidden: true,
            component: () => import('@/views/customer/CustomerForm'),
            meta: { title: 'menu.customer.form', keepAlive: true, permission: ['konsumen'] },
          },
          {
            hidden: true,
            path: '/customer/edit/:id([1-9]\\d*)?',
            hidden: true,
            name: 'Edit Customer',
            component: () => import('@/views/customer/CustomerForm'),
            meta: { title: 'Edit Customer', keepAlive: true, permission: ['konsumen'] },
          },
        ],
      },
      {
        path: '/notes',
        redirect: '/notes/list',
        component: BlankLayout,
        meta: { title: 'Catatan', icon: 'form', permission: ['catatan'] },
        children: [
          {
            path: '/notes/list',
            name: 'NotesList',
            component: () => import('@/views/notes/NotesList'),
            meta: { title: 'Catatan', keepAlive: true, permission: ['catatan'] },
          },

          {
            path: '/notes/add',
            name: 'NotesForm',
            hidden: true,
            component: () => import('@/views/notes/form/NotesForm'),
            meta: { title: 'Notes Form', keepAlive: true, permission: ['catatan'] },
          },
          {
            hidden: true,
            path: '/notes/view/:id([1-9]\\d*)?',
            hidden: true,
            name: 'Lihat Catatan',
            component: () => import('@/views/notes/NotesView'),
            meta: { title: 'Edit Customer', keepAlive: true, permission: ['catatan'] },
          },
          {
            hidden: true,
            path: '/notes/edit/:id([1-9]\\d*)?',
            hidden: true,
            name: 'Edit Catatan',
            component: () => import('@/views/notes/form/NotesForm'),
            meta: { title: 'Edit Catatan', keepAlive: true, permission: ['catatan'] },
          },
        ],
      },

      // forms
      // {
      //   path: '/form',
      //   redirect: '/form/base-form',
      //   component: RouteView,
      //   meta: { title: 'menu.form', icon: 'form', permission: ['form'] },
      //   children: [
      //     {
      //       path: '/form/base-form',
      //       name: 'BaseForm',
      //       component: () => import('@/views/form/basicForm'),
      //       meta: { title: 'menu.form.basic-form', keepAlive: true, permission: ['form'] }
      //     },
      //     {
      //       path: '/form/step-form',
      //       name: 'StepForm',
      //       component: () => import('@/views/form/stepForm/StepForm'),
      //       meta: { title: 'menu.form.step-form', keepAlive: true, permission: ['form'] }
      //     },
      //     {
      //       path: '/form/advanced-form',
      //       name: 'AdvanceForm',
      //       component: () => import('@/views/form/advancedForm/AdvancedForm'),
      //       meta: { title: 'menu.form.advanced-form', keepAlive: true, permission: ['form'] }
      //     }
      //   ]
      // },
      // list
      // {
      //   path: '/list',
      //   name: 'list',
      //   component: RouteView,
      //   redirect: '/list/table-list',
      //   meta: { title: 'menu.list', icon: 'table', permission: ['table'] },
      //   children: [
      //     {
      //       path: '/list/table-list/:pageNo([1-9]\\d*)?',
      //       name: 'TableListWrapper',
      //       hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
      //       component: () => import('@/views/list/TableList'),
      //       meta: { title: 'menu.list.table-list', keepAlive: true, permission: ['table'] }
      //     },
      //     {
      //       path: '/list/basic-list',
      //       name: 'BasicList',
      //       component: () => import('@/views/list/BasicList'),
      //       meta: { title: 'menu.list.basic-list', keepAlive: true, permission: ['table'] }
      //     },
      //     {
      //       path: '/list/card',
      //       name: 'CardList',
      //       component: () => import('@/views/list/CardList'),
      //       meta: { title: 'menu.list.card-list', keepAlive: true, permission: ['table'] }
      //     },
      //     {
      //       path: '/list/search',
      //       name: 'SearchList',
      //       component: () => import('@/views/list/search/SearchLayout'),
      //       redirect: '/list/search/article',
      //       meta: { title: 'menu.list.search-list', keepAlive: true, permission: ['table'] },
      //       children: [
      //         {
      //           path: '/list/search/article',
      //           name: 'SearchArticles',
      //           component: () => import('../views/list/search/Article'),
      //           meta: { title: 'menu.list.search-list.articles', permission: ['table'] }
      //         },
      //         {
      //           path: '/list/search/project',
      //           name: 'SearchProjects',
      //           component: () => import('../views/list/search/Projects'),
      //           meta: { title: 'menu.list.search-list.projects', permission: ['table'] }
      //         },
      //         {
      //           path: '/list/search/application',
      //           name: 'SearchApplications',
      //           component: () => import('../views/list/search/Applications'),
      //           meta: { title: 'menu.list.search-list.applications', permission: ['table'] }
      //         }
      //       ]
      //     }
      //   ]
      // },

      // profile
      {
        path: '/profile',
        name: 'profile',
        hidden: true,
        component: BlankLayout,
        redirect: '/profile/info',
        meta: { title: 'menu.profile', icon: 'profile', permission: ['user'] },
        children: [
          {
            path: '/profile/info',
            name: 'ProfileInfo',
            component: () => import('@/views/profile/info'),
            meta: { title: 'menu.profile.basic', permission: ['user'] },
          },
          // {
          //   path: '/profile/advanced',
          //   name: 'ProfileAdvanced',
          //   component: () => import('@/views/profile/advanced/Advanced'),
          //   meta: { title: 'menu.profile.advanced', permission: ['user'] },
          // },
        ],
      },

      // result
      // {
      //   path: '/result',
      //   name: 'result',
      //   component: RouteView,
      //   redirect: '/result/success',
      //   meta: { title: 'menu.result', icon: 'check-circle-o', permission: ['result'] },
      //   children: [
      //     {
      //       path: '/result/success',
      //       name: 'ResultSuccess',
      //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
      //       meta: { title: 'menu.result.success', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
      //     },
      //     {
      //       path: '/result/fail',
      //       name: 'ResultFail',
      //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),
      //       meta: { title: 'menu.result.fail', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
      //     }
      //   ]
      // },

      // Exception
      // {
      //   path: '/exception',
      //   name: 'exception',
      //   component: RouteView,
      //   redirect: '/exception/403',
      //   meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
      //   children: [
      //     {
      //       path: '/exception/403',
      //       name: 'Exception403',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
      //       meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
      //     },
      //     {
      //       path: '/exception/404',
      //       name: 'Exception404',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
      //       meta: { title: 'menu.exception.not-find', permission: ['exception'] }
      //     },
      //     {
      //       path: '/exception/500',
      //       name: 'Exception500',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
      //       meta: { title: 'menu.exception.server-error', permission: ['exception'] }
      //     }
      //   ]
      // },

      // account
      {
        path: '/account',
        component: RouteView,
        redirect: '/account/center',
        name: 'account',
        meta: { title: 'Setting', icon: 'setting', permission: ['setting'] },
        children: [
          // {
          //   path: '/account/center',
          //   name: 'center',
          //   component: () => import('@/views/account/center'),
          //   meta: { title: 'menu.account.center' },
          // },
          {
            path: '/account/settings',
            name: 'settings',
            component: () => import('@/views/account/settings/Index'),
            meta: { title: 'menu.account.settings', hideHeader: true, permission: ['setting'] },
            redirect: '/account/settings/basic',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/account/settings/basic',
                name: 'BasicSettings',
                component: () => import('@/views/account/settings/BasicSetting'),
                meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['setting'] },
              },
              {
                path: '/account/settings/service',
                name: 'ServiceSetting',
                component: () => import('@/views/account/settings/ServiceSetting'),
                meta: { title: 'Service Setting', hidden: true, permission: ['setting'] },
              },
              // {
              //   path: '/account/settings/security',
              //   name: 'SecuritySettings',
              //   component: () => import('@/views/account/settings/Security'),
              //   meta: {
              //     title: 'account.settings.menuMap.security',
              //     hidden: true,
              //     keepAlive: true,
              //     permission: ['user'],
              //   },
              // },
              // {
              //   path: '/account/settings/custom',
              //   name: 'CustomSettings',
              //   component: () => import('@/views/account/settings/Custom'),
              //   meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] },
              // },
              // {
              //   path: '/account/settings/binding',
              //   name: 'BindingSettings',
              //   component: () => import('@/views/account/settings/Binding'),
              //   meta: {
              //     title: 'account.settings.menuMap.binding',
              //     hidden: true,
              //     keepAlive: true,
              //     permission: ['user'],
              //   },
              // },
              // {
              //   path: '/account/settings/notification',
              //   name: 'NotificationSettings',
              //   component: () => import('@/views/account/settings/Notification'),
              //   meta: {
              //     title: 'account.settings.menuMap.notification',
              //     hidden: true,
              //     keepAlive: true,
              //     permission: ['user'],
              //   },
              // },
            ],
          },
        ],
      },

      // other
      /*
      {
        path: '/other',
        name: 'otherPage',
        component: PageView,
        meta: { title: '其他组件', icon: 'slack', permission: [ 'dashboard' ] },
        redirect: '/other/icon-selector',
        children: [
          {
            path: '/other/icon-selector',
            name: 'TestIconSelect',
            component: () => import('@/views/other/IconSelectorView'),
            meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: [ 'dashboard' ] }
          },
          {
            path: '/other/list',
            component: RouteView,
            meta: { title: '业务布局', icon: 'layout', permission: [ 'support' ] },
            redirect: '/other/list/tree-list',
            children: [
              {
                path: '/other/list/tree-list',
                name: 'TreeList',
                component: () => import('@/views/other/TreeList'),
                meta: { title: '树目录表格', keepAlive: true }
              },
              {
                path: '/other/list/edit-table',
                name: 'EditList',
                component: () => import('@/views/other/TableInnerEditList'),
                meta: { title: '内联编辑表格', keepAlive: true }
              },
              {
                path: '/other/list/user-list',
                name: 'UserList',
                component: () => import('@/views/other/UserList'),
                meta: { title: '用户列表', keepAlive: true }
              },
              {
                path: '/other/list/role-list',
                nameAND id_dana_tarik_fk IS NULL : 'RoleList',
                component: () => import('@/views/other/RoleList'),
                meta: { title: '角色列表', keepAlive: true }
              },
              {
                path: '/other/list/system-role',
                name: 'SystemRole',
                component: () => import('@/views/role/RoleList'),
                meta: { title: '角色列表2', keepAlive: true }
              },
              {
                path: '/other/list/permission-list',
                name: 'PermissionList',
                component: () => import('@/views/other/PermissionList'),
                meta: { title: '权限列表', keepAlive: true }
              }
            ]
          }
        ]
      }
      */
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
      },
      // {
      //   path: 'register',
      //   name: 'register',
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register'),
      // },
      // {
      //   path: 'register-result',
      //   name: 'registerResult',
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
      // },
      // {
      //   path: 'recover',
      //   name: 'recover',
      //   component: undefined,
      // },
    ],
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  },
  {
    path: '/print',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/print'),
  },
  {
    path: '/print_teknisi',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/print_teknisi'),
  },
  {
    path: '/print_teknisi_sparepart',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/print_teknisi_sparepart'),
  },
  {
    path: '/print_sticker',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/print_sticker'),
  },
]
